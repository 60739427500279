import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { ProbesService } from 'src/app/services/probes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-orderedit',
  templateUrl: './orderedit.component.html',
  styleUrls: ['./orderedit.component.scss']
})
export class OrdereditComponent implements OnInit {
  order: any = {};
  hideOrder = true;
  hideProbes = true;
  alertWrongOrder = false;
  nbOrder = '';
  pk: any;
  hideFormule = true;

  constructor(
    private orderService: OrderService,
    private probesService: ProbesService,
    private toastr: ToastrService) {
  }
  ngOnInit() {
  }
  // validation du formulaire de récupération d'information de la commande à partir du numero
  onnumberFormSubmit(numberForm) {
    this.nbOrder = numberForm.nb_order;
    this.orderService
            .getOrder(numberForm.nb_order)
            .subscribe(data => {
              this.alertWrongOrder = false;
              this.hideOrder = false;
              this.order = data;
              if (this.order.formule === 6) {
                this.hideFormule = true;
              } else {
                this.hideFormule = false;
              }
              if (this.order.probes.length > 0) {
                this.hideProbes = false;
              } else {
                this.hideProbes = true;
              }
            },
            error => {
              console.log('erreur', error );
              this.hideOrder = true;
              try {
                const stat = 'status';
                if (error[stat] === 404) {
                    this.alertWrongOrder = true;
                 }
              } catch (errorbis) {
                  console.log('erreur', errorbis );
              }
      });
  }
  // envoi du formulaire pour la modification des sondes de températures
  onorderFormSubmit() {
    this.probesService.putProbes(this.nbOrder, this.order.probes).subscribe(
      data => {
          this.toastr.success('Enregistré');
      },
      error => {
          console.log('erreur', error );
          try {
            const stat = 'status';
            if (error[stat] === 401) {
              this.toastr.error('erreur : non autorisé');
            } else {
              this.toastr.error('erreur');
            }
          } catch (errorbis) {
              console.log('erreur', errorbis );
          }
    });
  }
  // ouvre la fenetre d'imporession de la page
  printOrderForm() {
    window.print();
  }
}
