import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  uri = environment.api_url + '/mph/mphorder';

  postOrder(data) {
    return this.http.post(this.uri, data , {
      headers: new HttpHeaders({
           'Content-Type':  'application/json'
      })
    });
  }
  getOrder(nbOrder) {
    return this.http.get(`${this.uri + '?number=' + nbOrder}`);
  }
  getListForm() {
    return this.http.get(`${this.uri + '/listform'}`);
  }
  getListTimezone() {
    return this.http.get(`${this.uri + '/listtimezone'}`);
  }
  /* récupération de la liste des types de formations */
  getListFormation() {
    return this.http.get(`${this.uri + '/listformation'}`);
  }
}
