import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReactiveFormsModule } from '@angular/forms';

// bibliotheques
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SidebarModule } from 'ng-sidebar';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgxPermissionsModule } from 'ngx-permissions';

// Utils
import { AuthorizationInterceptor } from './utils/interceptor';

// pages
import { HomeComponent } from './pages/home/home.component';
import { OrdereditComponent } from './pages/orderedit/orderedit.component';
import { NgbdModalContentComponent } from './modal/modal.component';

// services
import { LanguagesService } from './services/languages.service';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        OrdereditComponent,
        NgbdModalContentComponent
    ],
    entryComponents: [NgbdModalContentComponent],
    exports: [ NgbdModalContentComponent ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        AngularFontAwesomeModule,
        SidebarModule.forRoot(),
        HttpClientModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        }),
        NgProgressModule.withConfig({
            trickleSpeed: 0,
            debounceTime: 250,
            speed: 0
        }),
        NgProgressHttpModule,
        NgxPermissionsModule.forRoot(),
        QRCodeModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        LanguagesService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
