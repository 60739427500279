import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">AVERTISSEMENT !</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Attention ne pas saisir le nombre d'abonnements sondes <br> dans nb d'abonnement HE HACCP.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Ok, j'ai compris.</button>
    </div>
  `
})
export class NgbdModalContentComponent {

  constructor(public activeModal: NgbActiveModal) {}
}

