import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// ajoute le token "Autorization" à chaque requete
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        const modified = req.clone({ setHeaders: { Authorization: localStorage.getItem('tokenKey'), ContentType: 'application/json' } });
        return next.handle(modified);
    }
}
