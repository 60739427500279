import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
declare let HecIcon: any; // declaration de la variable du script js qui sera téléchargé pour le bouton

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

    constructor(
        translate: TranslateService,
        private authenticationService: AuthenticationService,
        private permissionsService: NgxPermissionsService,
        ngProgress: NgProgress
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('en');
        // Get an instance of NgProgressRef
        this.progressBar = ngProgress.ref();
        // Get progress bar events (optional)
        this.progressBar.started.subscribe(() => this.onStarted());
        this.progressBar.completed.subscribe(() => this.onCompleted());
    }

    // Progress bar reference
    progressBar: NgProgressRef;
    showSpinner = false;
    // menu lateral
    opened = false;
    dock = true;
    mode = 'push';
    showMenu = '';

    // afficher / masquer la barre lateral
    toggleSidebar() {
        this.opened = !this.opened;
    }

    // affichages des sous menus de la barre lateral
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    // Afficher / masquer le spinner
    onStarted() {
        this.showSpinner = true;
        console.log('started');
    }

    onCompleted() {
        console.log('stoped');
        this.showSpinner = false;
    }

    // Chargement du script btn_hec
    /* private loadBtnHecJsFile(callback: any) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
            callback();
        };
        script.src = environment.url_btn_hec + '?key=' + this.generateKey(6);
        document.getElementsByTagName('header')[0].appendChild(script);
    } */

    // Generation d'une clé unique
    /* private generateKey(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } */

    ngOnInit() {
        localStorage.setItem('tokenKey', 'suput-a1f81924-72eb-4b11-a936-71d4c353a811');
        sessionStorage.setItem('tokenKey', 'suput-a1f81924-72eb-4b11-a936-71d4c353a811');
        sessionStorage.setItem('currentUser', '479');
        sessionStorage.setItem('userName', 'Seba');
        sessionStorage.setItem('userId', '479');
        sessionStorage.setItem('userRole', '1');
        sessionStorage.setItem('entrepriseId', '98');

        /* // recuperation des infos concernant l'utilisateur
        this.authenticationService
            .autoLogin(this.generateKey(6))
            .subscribe((data: any) => {
                this.loadBtnHecJsFile = () => {
                    const heBtnDatas = {
                        currentUser: null,
                        currentCompany: null
                    };
                    heBtnDatas.currentUser = data;
                    heBtnDatas.currentCompany = data.companies[0];
                    HecIcon.show(heBtnDatas);
                };
            }, err => {
                console.log(err);
            }); */

        // initialisation des permissions de l'utilisateur :
        // https://github.com/AlexKhymenko/ngx-permissions/wiki
        // exemple avec des permissions en dur:
        const perm = ['ADMIN', 'EDITOR'];
        this.permissionsService.loadPermissions(perm);
        // normalement il faut charger les permissions depuis l'API genre :
        // this.http.get('url').subscribe((permissions) => {
        // this.permissionsService.loadPermissions(permissions);
        // voir sur le menu pour un exemple d'utilisation des permissions
    }
}
