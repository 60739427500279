import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    constructor(private http: HttpClient) { }

    uri = environment.api_url + '/authentication/autologin?key=';
    autoLogin(key: string) {
        return this.http.post(`${this.uri}` + key, null);
    }
}
