import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class LanguagesService {
    constructor(private http: HttpClient) { }

    uri = environment.api_url + '/languages';
    getAll() {
        return this.http.get(`${this.uri}`);
    }
}
