import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OrdereditComponent } from './pages/orderedit/orderedit.component';
const routes: Routes = [
    {
        path: 'new',
        component: HomeComponent
    },
    {
        path: 'orderedit',
        component: OrdereditComponent
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

