import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProbesService {

  constructor(private http: HttpClient) { }

  uri = environment.api_url + '/mph/probes';

  putProbes(nbOrder, data) {
    return this.http.put(this.uri + '?ordernumber=' + nbOrder, data , {
      headers: new HttpHeaders({
           'Content-Type':  'application/json'
      })
    });
  }
}
